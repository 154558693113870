body {
	background-color: #f5f5f5 !important;
}
.app-header-wrapper .logo img,
.cus_logo_width {
	width: 200px;
}
.top-nav-wrapper .ant-menu-submenu-selected,
.top-nav-wrapper .ant-menu-item-selected {
	background-color: #3e79f71a !important;
	padding: 0px 15px !important;
}

.licence_div .label_div {
	width: 110px;
	margin-right: 15px;
	color: #4d4b4b;
}

.licence_div .value_div {
	font-weight: 500;
	color: #1a3353;
}

/* Garment wash details modal CSS */

.garment_wash {
	max-height: 150px !important;
	overflow-y: overlay !important;
}

.garment_wash_modal .wash_dates_selections {
	margin-top: 5px !important;
	margin-bottom: 10px !important;
}

.garment_wash_modal .resident_details_label {
	width: 15% !important;
}

/* width */
.garment_wash::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.garment_wash::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.garment_wash::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.garment_wash::-webkit-scrollbar-thumb:hover {
	background: #555;
}
/* .garment_wash .wash_dates div {
    width: 33.33% !important;
} */

/* .ant-picker-dropdown{
    transform: translateX(-125px) !important;
} */

.mtag_main_header li {
	margin: 0 !important;
}

.mtag_main_header span,
.mtag_main_header span {
	padding: 12px;
}
.mtag_main_header .ant-menu-item-selected span,
.mtag_main_header .ant-menu-submenu-selected span {
	background-color: #f9f8fb;
	border-radius: 8px;
	color: #173f70;
	/* padding: 12px; */
}
.footer_text {
	position: absolute;
	bottom: 10px;
}

.dashboard-view-type {
	padding: 5px;
	background-color: #f9fafb;
	border: 1px solid #f2f4f7;
	border-radius: 10px;
}

.capacity_column {
	border-right: 1px solid #e4e7ec;
	padding: 0 10px 0 0;
	margin: 0 0px;
}
